import React, { useEffect, useRef, useState } from 'react';
import { useAnimation, motion } from 'framer-motion';

const Reveal = ({ children, width = 'fit-content' }) => {
  const mainControls = useAnimation();
  const slideControls = useAnimation();
  const [revealed, setRevealed] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setRevealed(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (revealed) {
      slideControls.start('visible');
      mainControls.start('visible');
    }
  }, [revealed, mainControls, slideControls]);

  return (
    <div ref={ref} style={{ position: 'relative', width, overflow: 'hidden' }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        {children}
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: '100%' },
        }}
        initial="hidden"
        animate={revealed ? 'visible' : 'hidden'}
        transition={{ duration: 0.5, ease: 'easeIn' }}
        style={{
          position: 'absolute',
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background: 'var(--brand)', // Change this color or use a defined variable
          zIndex: 20,
        }}
      />
    </div>
  );
};

export default Reveal;
